<template>
  <div class="all-documents">
      <div class="all-documents-search">
          <el-input v-model="search" style="width:100%"  placeholder="חיפוש מסמך" />
          <i class="material-icons serach_icon">search</i>
      </div>
      <div v-loading="is_panding" class="all-documents-contects">
          <p class="no-documents" v-if="all_documents.length==0">אין מסמכים להצגה</p>
          <template v-for="document in filter_by_search" :key="document.id">
              <div class="document">
                <p class="document-name">{{document.document_name}}</p>
                <i v-if="role>=99" @click="handle_delete(document)" class="material-icons delete-icon">delete</i>
                <i @click="download_doc(document)" class="material-icons download-icon">file_download</i>
                <small>{{new Date(document.created_at.seconds * 1000).toLocaleDateString('he')}}</small>
                <div class="doc-icon">   
                    <i class="material-icons">description</i>
                </div>
              </div>
          </template>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import {projectFirestore} from '../../../firebase/config'
import use_documents_storage from '../../../Methods/use_documents_storage'
import {alert_confirm} from '../../../Methods/Msgs'
import store from '../../../store'

export default {
    setup(){
        const {deleteImage} =  use_documents_storage()
        const search = ref('')
        const all_documents = ref([])
        const is_panding = ref(false)
        const filter_by_search = ref(computed(()=>{
            if(search.value =='') return all_documents.value
            return all_documents.value.filter(doc=>{
                if(doc.document_name.includes(search.value)) return doc
            })
        }))
        const download_doc=(doc)=>{
            window.open(doc.url,'_blank');
        } 
        const role = ref(computed(()=>{
            return store.state.role
        }))
        const handle_delete = (doc)=>{
            alert_confirm('האם את בטוחה שאת רוצה למחוק את המסמך?')
            .then(async res=>{
                if(res.isConfirmed){
                    await deleteImage(doc.file_path)
                    await delete_doc_from_db(doc.id)
                    const index = all_documents.value.findIndex(_doc=>_doc.id==doc.id)
                    if(index!=-1){
                        all_documents.value.splice(index,1)
                    }
                }
            })
        }
        const delete_doc_from_db = async(doc_id)=>{
            await projectFirestore.collection('Applications').doc('1wEjkld8agzqXCaefeZ1').collection('Documents').doc(doc_id).delete()
        }
        const get_all_documents_from_db = async()=>{
            is_panding.value = true
            const docs = await projectFirestore.collection('Applications').doc('1wEjkld8agzqXCaefeZ1').collection('Documents').get()
            all_documents.value = docs.docs.map(doc=>doc.data())
            is_panding.value = false
        }
        onMounted(()=>{
            get_all_documents_from_db()
        })
        return{
            all_documents,
            download_doc,
            handle_delete,
            filter_by_search,
            search,
            is_panding,
            role
        }
    }
}
</script>

<style scoped>
    .all-documents{
        width: 100%;
        height: 100%;
    }
    .all-documents-search{
        position: relative;
        padding: 0 10px;
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .serach_icon{
        position: absolute;
        top:15px;
        left: 20px;
        color: #fff;
        text-shadow:4px 4px 2px rgba(150, 150, 150, 1);;
    }
    .all-documents-contects{
        padding: 0 10px;
        width: 100%;
        height: calc(100% - 60px);
        overflow: hidden;
        overflow-y: auto;
    }
    .document{
        margin-bottom: 5px;
        padding-left: 5px;
        padding-right: 50px ;
        position: relative;
        width: 100%;
        height: 70px;
        background:#fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .document small{
        position: absolute;
        top: 0;
        right: 20px;
        color:cornflowerblue ;
    }
    .document-name{
        width:100%;
        overflow:auto;
        font-size: 18px;
        color: darkslateblue;
        font-weight: 500;
    }
    .doc-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -8px;
        width: 30px;
        height: 30px;
        background: whitesmoke;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
        
    }
    .doc-icon i{
        color: cornflowerblue;
        font-size: 15px;
    }
    .download-icon,.delete-icon{
        color: cornflowerblue;
        cursor: pointer;
    }
    .delete-icon{
        color: crimson;
    }
    .no-documents{
        font-size: 50px;
        color: #fff;
        text-align: center;
    }
</style>